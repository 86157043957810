.App {
  text-align: center;
}

.App-header {
  background-color: #151515;
  padding: 20px;
  color: white;
  margin-bottom: 20px;
  border-bottom: 1px solid whitesmoke;
}

main {
  display: flex;
  justify-content: center;
}

.color-palette-generator {
  max-width: 600px;
  width: 100%;
}

.color-swatches {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.color-swatches > div {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  border-radius: 5px;
}